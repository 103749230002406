import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Media } from "reactstrap";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import {
  formatPrice,
  GetDefaultCurrencyCode,
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
} from "../../../helpers/CommonHelper";
import Config from "../../../helpers/Config";
import { LOADER_DURATION } from "../../../helpers/Constants";
import {
  makeProductShortDescription,
  replaceWhiteSpacesWithDashSymbolInUrl,
} from "../../../helpers/ConversionHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";
import rootAction from "../../../stateManagment/actions/rootAction";
import useMobileSize from "helpers/utils/isMobile";
import { Skeleton } from "antd";
import ProductBox from "./ProductBox";
import Slider from "react-slick";

var settings = {
  arrows: true,
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 6,
  slidesToScroll: 1,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 1700,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        infinite: true,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};
const SidePopularProducts = () => {
  const dispatch = useDispatch();
  const isTablet = useMobileSize(900);
  const [PopularProductsList, setPopularProductsList] = useState([]);
  // const [adminPanelBaseURL, setBaseUrl] = useState(Config["ADMIN_BASE_URL"]);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const currency = useRef(GetDefaultCurrencyCode());
  const GetPopularProductsForLeftSideBar = async () => {
    const headersPouplarProducts = {
      // customerid: userData?.UserID,
      // customeremail: userData.EmailAddress,
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const paramPouplarProducts = {
      requestParameters: {
        PageNo: 1,
        PageSize: 10,
        recordValueJson: "[]",
        Currency: currency.current,
      },
    };

    const responsePopularProducts = await MakeApiCallAsync(
      Config.END_POINT_NAMES["GET_POPULAR_PRODUCTS_LIST"],
      null,
      paramPouplarProducts,
      headersPouplarProducts,
      "POST",
      true
    );
    if (
      responsePopularProducts !== null &&
      responsePopularProducts.data !== null
    ) {
      await setPopularProductsList(
        JSON.parse(responsePopularProducts.data.data)
      );
      console.log(JSON.parse(responsePopularProducts.data.data));

      console.log(
        "PopularProducts",
        JSON.parse(responsePopularProducts.data.data)
      );
    }
  };

  const forceLoadProduct = (url) => {
    window.location.href = url;
  };

  useEffect(() => {
    const GetFiltersAllValues = async () => {
      //--get popular products list
      await GetPopularProductsForLeftSideBar();

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["SiteLeftSidebarFilter"],
        null
      );
      if (
        arryRespLocalization !== null &&
        arryRespLocalization !== undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };

    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    GetFiltersAllValues().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  }, []);

  return (
    <>
      <div className="theme-card creative-card creative-inner product-service-block mt-4">
        <h5>
          {LocalizationLabelsArray.length > 0
            ? replaceLoclizationLabel(
                LocalizationLabelsArray,
                "Popular Products",
                "lbl_lftfilt_pop_prod"
              )
            : "Popular Products"}
        </h5>
        {isTablet ? (
          <div className="product mt-3">
            <div className="product-slide-6 ratio_asos no-arrow">
              {!PopularProductsList || PopularProductsList.length < 0 ? (
                <Skeleton />
              ) : (
                <Slider {...settings}>
                  {PopularProductsList &&
                    PopularProductsList.map((item, i) => (
                      <div
                        key={i}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          forceLoadProduct(
                            `/${getLanguageCodeFromSession()}/product-detail/${
                              item.ProductId
                            }/${
                              replaceWhiteSpacesWithDashSymbolInUrl(
                                item.CategoryName
                              ) ?? "shop"
                            }/${replaceWhiteSpacesWithDashSymbolInUrl(
                              item.ProductName
                            )}`
                          );
                        }}
                      >
                        <ProductBox
                          hoverEffect={""}
                          item={item}
                          layout=""
                          ProductDetailPageForceUpload={false}
                        />
                      </div>
                    ))}
                </Slider>
              )}
            </div>
          </div>
        ) : (
          <div className="offer-slider slide-1">
            <div>
              {PopularProductsList?.map((item, idx) => (
                <div
                  className="media"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    forceLoadProduct(
                      `/${getLanguageCodeFromSession()}/product-detail/${
                        item.ProductId
                      }/${
                        replaceWhiteSpacesWithDashSymbolInUrl(
                          item.CategoryName
                        ) ?? "shop"
                      }/${replaceWhiteSpacesWithDashSymbolInUrl(
                        item.ProductName
                      )}`
                    );
                  }}
                >
                  <Link to="#">
                    {item?.ProductImagesJson?.slice(0, 1).map((img, imgIdx) => (
                      <>
                        <Media
                          className="img-fluid "
                          src={img.AttachmentURL}
                          alt="side popular product"
                        />
                      </>
                    ))}
                  </Link>
                  <div className="media-body align-self-center">
                    <h6>{makeProductShortDescription(item.ProductName, 20)}</h6>
                    <h4>{formatPrice(item.Price, item.Currency)}</h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SidePopularProducts;
